<template>
  <div :class="{active:show}" class="menu-container">
    <mouse-changer type="BlendMode">
      <div :class="{hide:showChild}" class="menu-list">
        <div class="item"
             @click="setShowChild(item)"
             v-for="(item,index) in menu"
             @mouseover="activeIndex=index"
             @mouseout="activeIndex=null"
             :class="{geo:isGeo}"
        >
          <h1 style="font-weight: 300">{{ item.title }}</h1>
        </div>
      </div>
      <menu-child :class="{active:showChild}"
                  :active-item="activeItem"
                  :active-child="activeChild"
                  @closeMenu="closeMenu"
                  @close="showChild=false" />
      <div class="grid load">
        <div class="vertical">
          <div id="vertical-grid-container" class="vertical-grid-container gap">
            <div v-for="index in 7" :key="index"
                 class="grid-vertical-item"></div>
          </div>
        </div>
        <div class="horizontal gap">
          <div v-for="index in 3" :key="index"
               class="grid-horizontal-item"></div>
          <div class="mobile-horizontal-item"></div>
        </div>
      </div>
      <div class="hide-images">
        <div :class="{active:activeIndex===index}" v-for="(item,index) in images" class="item">
          <video class="video"
                 autoplay
                 loop
                 muted
                 v-if="item.video"
          >
            <source :src="item.video" type="video/mp4">
            {{$tr('Your browser does not support the video tag.')}}
          </video>
          <img v-else :src="item.image" alt="">
        </div>
      </div>
    </mouse-changer>
  </div>
</template>

<script>
import MenuChild from "@/components/MenuChild.vue";
import { mapActions, mapGetters } from "vuex";
import MouseChanger from "@/components/MouseChanger";

export default {
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      activeItem: [],
      showChild: false,
      activeIndex: null,
      resizeTimeOut: null
    };
  },
  components: {
    MouseChanger,
    MenuChild
  },
  computed: {
    ...mapGetters({
      menu: "menu/menu",
      lang: "lang/activeLang"
    }),
    isGeo() {
      return this.lang === "ka";
    },
    images() {
      return this.menu?.map(item => ({
        video: item?.content?.video,
        image: item?.content?.images?.[0]?.file
      }));
    },
    activeChild() {
      return this.activeItem?.children;
    }
  },
  methods: {
    ...mapActions({
      getMenu: "menu/getMenu"
    }),
    setShowChild(item) {
      if (item.children.length) {
        this.activeItem = item;
        this.showChild = true;
      } else {
        this.$router.push({
          path: `/${this.lang}/${item.url}`
        }).then(() => {
          this.$emit("close");
        });
      }
    },
    setFirstChild(item) {
      let left = document.querySelector("#vertical-grid-container .grid-vertical-item:last-child")?.getBoundingClientRect().left;
      let top = document.querySelector(".horizontal .grid-horizontal-item:first-child")?.getBoundingClientRect().top;
      item.style.left = `${left}px`;
      item.style.top = `${top}px`;
    },
    setSecondChild(item) {
      let left = document.querySelector("#vertical-grid-container .grid-vertical-item:first-child")?.getBoundingClientRect().left;
      let top = document.querySelector(".horizontal .grid-horizontal-item:nth-child(2)")?.getBoundingClientRect().top;
      item.style.left = `${left}px`;
      item.style.top = `${top}px`;
    },
    setThirdChild(item) {
      let left = document.querySelector("#vertical-grid-container .grid-vertical-item:nth-child(6)")?.getBoundingClientRect().left;
      let top = document.querySelector(".horizontal .grid-horizontal-item:nth-child(3)")?.getBoundingClientRect().top;
      item.style.left = `${left}px`;
      item.style.top = `${top}px`;
    },
    setFourthChild(item) {
      let left = document.querySelector("#vertical-grid-container .grid-vertical-item:nth-child(1)")?.getBoundingClientRect().left;
      let top = document.querySelector(".horizontal .grid-horizontal-item:nth-child(3)")?.getBoundingClientRect().top;
      item.style.left = `${left}px`;
      item.style.top = `${top}px`;
    },
    setFifthChild(item) {
      let left = document.querySelector("#vertical-grid-container .grid-vertical-item:last-child")?.getBoundingClientRect().left;
      let top = document.querySelector(".horizontal .grid-horizontal-item:first-child")?.getBoundingClientRect().top;
      item.style.left = `${left}px`;
      item.style.top = `${top}px`;
    },
    setPositions() {
      let items = document.querySelectorAll(".hide-images .item");

      this.setFirstChild(items[0]);
      this.setSecondChild(items[1]);
      this.setThirdChild(items[2]);
      this.setFourthChild(items[3]);
      this.setFifthChild(items[4]);
    },
    resizeHandler() {
      this.resizeTimeOut = setTimeout(() => {
        this.setPositions();
      }, 800);
    },
    closeMenu() {
      this.$emit("close");
    }
  },
  created() {
    this.getMenu();
    window.addEventListener("resize", this.resizeHandler);
  },
  watch: {
    show(val) {
      if (val) this.setPositions();
      this.showChild = false;
    }
  },
  beforeDestroy() {
    if (this.resizeTimeOut) clearTimeout(this.resizeTimeOut);
    window.removeEventListener("resize", this.resizeHandler);
  }
};

</script>

<style lang="scss" scoped>
$ease: cubic-bezier(.3, -0.01, 0, .99);
$imageEase: cubic-bezier(0, .26, .31, 1);
$time: .8s;

.menu-container {
  position: fixed;
  width: 100%;
  height: 100vh;
  background: transparent;
  top: 0;
  transition: all $time $ease;
  pointer-events: none;

  &.active {
    background: black;
    pointer-events: all;
    transition: all .1s $ease;

    .menu-list {
      .item {
        transition: all $time $ease;
        opacity: 1;
        letter-spacing: 0.25em;

        &.geo {
          letter-spacing: .1em;
        }

        @for $i from 3 through 9 {
          &:nth-child(#{$i}) {
            transition: all $time $i/20+s $ease;
          }
        }
      }
    }

    .grid {
      opacity: 1;
      transition: all .1ms $ease;
    }
  }

  .menu-list {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: all $time .3s $ease;
    margin-top: -10px;
    @media only screen and (max-width: 1279px) and (min-width: 1024px) {
      margin-top: -8px;

    }

    .item {
      transition: all $time $ease;
      opacity: 0;
      letter-spacing: 0.1em;
    }

    &.hide {
      opacity: 0;
      transform: translate(-50%, -50%) scale(.8);
      transition: all $time $ease;
    }
  }

  .item {
    position: relative;
    font-weight: 300;
    font-size: 55px;
    line-height: 71px;
    letter-spacing: 0.25em;
    text-transform: uppercase;
    color: white;
    text-align: center;
    cursor: pointer;
    @media only screen and (max-width: 1650px) {
      font-size: 34px;
      line-height: 44px;
    }

    &:nth-child(2) {
      span {
        &:after {
          background: #C3E4DE;
        }
      }
    }

    &:nth-child(3) {
      span {
        &:after {
          background: #F5C082;
        }
      }
    }

    &:nth-child(4) {
      span {
        &:after {
          background: #CBA6E9;
        }
      }
    }

    &:nth-child(5) {
      span {
        &:after {
          background: #F3F3F1;
        }
      }
    }

    &.geo {
      h1 {
        &:hover {
          letter-spacing: .2em;
        }
      }
    }

    h1 {
      transition: all 0.7s cubic-bezier(0, 0.12, 0.27, 1.01);

      &:hover {
        letter-spacing: .3em;

        &:after {
          transform: scaleX(1) translateX(-50%);
        }
      }

      &:after {
        position: absolute;
        bottom: 0;
        left: 50%;
        content: '';
        height: 2px;
        width: 88px;
        background: #FD9B89;
        transform: scaleX(0) translateX(-50%);
        transition: all 0.7s cubic-bezier(0, 0.12, 0.27, 1.01);
        transform-origin: left;
      }
    }
  }

  .grid {
    $gap: 161px;
    height: 100vh;
    overflow-x: hidden;
    top: 0;
    z-index: 2;
    width: 100%;
    opacity: 0;
    pointer-events: none;

    &.load {
      .grid-horizontal-item {
        transform: translateX(0) !important;
      }

      .grid-vertical-item {
        &:first-child {
          transform: translateY(0) !important;
        }

        &:nth-child(2), &:nth-child(6) {
          transform: translateY(0) !important;
        }

        &:nth-child(3) {
          transform: translateY(-5%) !important;
        }

        &:nth-child(4) {
          transform: translateY(12.5%) !important;
        }

        &:nth-child(5) {
          transform: translateY(8%) !important;
        }

        &:nth-child(7) {
          transform: translateY(-2.5%) !important;
        }
      }
    }

    .gap {
      grid-gap: $gap;
    }

    .vertical {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      left: 0;
      height: 100%;
      width: 100%;
      transition: all $time $ease;
    }

    .vertical-grid-container {
      position: relative;
      width: fit-content;
      height: 100vh;
      display: flex;
      align-items: center;
      transition: all $time $ease;

      .grid-vertical-item {
        transition: all $time $ease;

        &:first-child {
          transform: translateY(-120%);
          transition-delay: .4s;
        }

        &:nth-child(2) {
          transform: translateY(120%);
          transition-delay: .1s;
        }

        &:nth-child(3) {
          transform: translateY(-120%);
          transition-delay: .3s;
        }

        &:nth-child(4) {
          transform: translateY(120%);
          transition-delay: .25s;
        }

        &:nth-child(5) {
          transform: translateY(-120%);
          transition-delay: .2s;
        }

        &:nth-child(6) {
          transform: translateY(-120%);
          transition-delay: .1s;
        }

        &:nth-child(7) {
          transform: translateY(120%);
        }
      }
    }

    .grid-vertical-item {
      width: 2px;
      height: 100%;
      background: rgba(149, 107, 84, 0.2);

      &:first-child {
        height: 70%;
      }

      &:nth-child(2), &:nth-child(6) {
        height: 55%;
      }

      &:nth-child(3) {
        height: 60%;
        transform: translateY(-7%);
      }

      &:nth-child(4) {
        height: 80%;
        transform: translateY(12.5%);
      }

      &:nth-child(5) {
        height: 72%;
        transform: translateY(8%);
      }

      &:nth-child(7) {
        height: 65%;
        transform: translateY(-10%);
      }
    }

    .vertical-grid-hidden-container {
      position: relative;
      height: 100vh;
      top: 0;
      display: flex;
      align-items: center;
      width: fit-content;
      right: 0;
      //transform: translateX(100%);
      transition: all $time $ease;

      &.right {
        //transform: translateX(-100%);
        //left: 0;
        //right: unset;
      }

      .grid-vertical-item {
        transition: all $time .2s $ease;
        transform-origin: top;
        opacity: 0;

        &:nth-child(1) {
          height: 55%;
          transform: translateY(-60%) scaleY(0);
          transition: all $time .4s $ease;
        }

        &:nth-child(2) {
          height: 60%;
          transform: translateY(60%) scaleY(0);
          transform-origin: bottom;
        }

        &:nth-child(3) {
          height: 65%;
          transform: translateY(-65%) scaleY(0);
        }
      }
    }

    .horizontal {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      @media only screen and (max-width: 1023px) {
        transform: translateY(-65%);
      }

      .grid-horizontal-item {
        height: 2px;
        width: 100%;
        background: rgba(149, 107, 84, 0.2);
        transform: translateX(120%);
        transition: all 1.2s $ease;

        &:nth-child(1) {
          width: 70%;
          transition-delay: .2s;
        }

        &:nth-child(2) {
          width: 83%;
          align-self: flex-start;
          transition-delay: .3s;
        }

        &:nth-child(3) {
          width: 85%;
          align-self: flex-end;
          transform: translateX(-120%);
        }

        @media only screen and (max-width: 1023px) {
          width: 100% !important;
        }

        &.mobile-horizontal-item {
          display: none;
          @media only screen and (max-width: 1023px) {
            display: block;
          }
        }
      }
    }

    @media only screen and (max-width: 1650px) and (min-width: 1440px) {
      $gap: 120px;
      .gap {
        grid-gap: $gap;
      }
      &.from-left {
        .vertical {
          transform: translateX(calc(3 * -124px));
        }
      }

      &.from-right {
        .vertical {
          transform: translateX(calc(100vw - (9 * 122px)));
        }
      }
    }
    @media only screen and (max-width: 1439px) and (min-width: 1280px) {
      $gap: 106px;
      .gap {
        grid-gap: $gap;
      }
    }
    @media only screen and (max-width: 1279px) and (min-width: 768px) {
      $gap: 84px;
      .gap {
        grid-gap: $gap;
      }
    }
    @media only screen and (max-width: 767px) {
      $gap: 94px;
      .gap {
        grid-gap: $gap;
      }
    }
  }

  .hide-images {
    position: absolute;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    pointer-events: none;

    .item {
      $gap: 161px;
      display: flex;
      align-items: flex-end;
      position: absolute;
      opacity: 0;
      clip-path: polygon(100% 0%, 100% 0%, 100% 100%, 100% 100%);
      transition: all .8s $imageEase;
      transform: translate(-100%, -100%);
      width: $gap;
      @media only screen and (max-width: 1650px) and (min-width: 1440px) {
        $gap: 120px;
        width: $gap;
        &:nth-child(3) {
          width: calc(120px * 2);
        }
      }
      @media only screen and (max-width: 1439px) and (min-width: 1280px) {
        $gap: 106px;
        width: $gap;
        &:nth-child(3) {
          width: calc(106px * 2);
        }
      }
      @media only screen and (max-width: 1279px) and (min-width: 768px) {
        $gap: 84px;
        width: $gap;
        &:nth-child(3) {
          width: calc(84px * 2);
        }
      }
      @media only screen and (max-width: 767px) {
        $gap: 94px;
        width: $gap;
        &:nth-child(3) {
          width: calc(94px * 2);
        }
      }

      &:nth-child(3) {
        width: calc(161px * 2);
      }

      &.active {
        clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
        opacity: 1;
      }

      &:nth-child(3), &:nth-child(4), &:nth-child(5) {
        transform: translate(0%, 0%);

      }
    }

    .video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

}
</style>
import Vue from "vue";

let $vm = Vue.prototype;

export default {
  namespaced: true, state: () => ({
    _lang: [], _activeLang: "en"
  }), actions: {
    getLang({ commit }) {
      return $vm.$axios.get("/getLangs").then(({ data }) => {
        commit("SET_LANG", data);
      });
    }, setActiveLang({ commit }, data) {
      commit("SET_ACTIVE_LANG", data);
    }
  }, mutations: {
    SET_LANG(state, data) {
      state._lang = data.langs;
    }, SET_ACTIVE_LANG(state, data) {
      state._activeLang = data;
    }
  }, getters: {
    lang(state) {
      return state._lang;
    }, activeLang(state) {
      return state._activeLang;
    }
  }
};

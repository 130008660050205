<template>
  <div id="app">
    <router-view />
    <portal-target name="video">
    </portal-target>
    <transition name="loader">
      <loader v-if="loader" />
    </transition>
    <auth-modal></auth-modal>
    <cart-modal />
    <portal-target name="terms">
    </portal-target>
  </div>
</template>

<script>
import Loader from "@/components/Loader";
import { mapGetters } from "vuex";
import AuthModal from "./components/auth/AuthModal.vue";
import CartModal from "./components/cart/CartModal.vue";

export default {
  data() {
    return {
      windowWidth: 0
    };
  },
  components: {
    Loader,
    AuthModal,
    CartModal
  },
  computed: {
    ...mapGetters({
      loader: "loader/loader"
    })
  },
  beforeCreate(){
    this.$store.dispatch('getTranslations');
    this.$store.dispatch('initUser');
    this.$store.dispatch('initCart');
    this.$store.dispatch('getContries');
    this.$store.dispatch('getUser');
  }
};
</script>

<style lang="scss">
$ease: cubic-bezier(0.92, 0.01, 0.31, 0.99);

#app {
  @media (max-width: 1023px) {
    overflow-y: scroll;
    height: 100vh;
  }
}

.loader-enter-active {
  transition: all .6s $ease;
  transform: translateY(0);

  .logo {
    opacity: 1;
    transform: translateY(0);
    transition: all .3s .4s $ease;
  }

}

.loader-leave-active {
  transition: all .6s .2s $ease;
  transform: translateY(0);

  .logo {
    transition: all .45s $ease;
  }
}

.loader-leave-to {
  transition: all .6s .5s $ease;
  transform: translateY(-100vh);

  .logo {
    opacity: 0;
    transform: translateY(-30px);
  }

}

.loader-enter {
  transform: translateY(100vh);

  .logo {
    opacity: 0;
    transform: translateY(30px);
  }
}
</style>

import Vuex from "vuex";
import Vue from "vue";
import header from "./modules/header";
import lang from "./modules/lang";
import menu from "./modules/menu";
import projects from "./modules/projects";
import about from "./modules/about";
import contact from "./modules/contact";
import loader from "./modules/loader";
import products from "./modules/products";
import sliderContent from "./modules/sliderContent";
import auth from './auth/index'
import cart from './cart/index'
import common from './common/index'
import address from './address/index'
import scroll from "./modules/scroll";
import mouseCursor from "./modules/mouseCursor";
import translations from './translations/index'

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    header,
    lang,
    menu,
    projects,
    about,
    sliderContent,
    contact,
    loader,
    products,
    scroll,
    auth,
    cart,
    common,
    address,
    mouseCursor,
    translations
  }
});

import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import Layout from "../views/Layout.vue";
import store from "@/store";
import LocomotiveScroll from "locomotive-scroll";

Vue.use(VueRouter);
const route = [{
  path: "", name: "home", component: HomeView
}, {
  path: "account", name: "Account", meta: { requiresAuth: true }, component: () => import("../views/Account.vue")
}, {
  path: "checkout", name: "Checkout", component: () => import("../views/Checkout.vue")
}, {
  path: "terms", name: "Terms", component: HomeView
}, {
  path: "about", name: "about", component: () => import(/* webpackChunkName: "about" */ "../views/AboutView.vue")
}, {
  path: "projects", name: "projects", component: () => import(/* webpackChunkName: "about" */ "../views/ProjectsView")
}, {
  path: "projects/:id", component: () => import("../views/ProjectsInner"), children: [{
    path: "", component: () => import("../views/ProjectsView"), name: "projectInner"
  }]
}, {
  path: "contact", name: "contact", component: () => import("../views/Contact")
}, {
  path: "media", component: () => import("../views/MediaView")
}, {
  path: "media/:id", component: () => import("../views/MediaInner"), children: [{
    path: "", name: "media", component: () => import("../views/MediaView")
  }]
}, {
  path: "shop", name: "shop", component: () => import("../views/ShopView")
}, {
  path: "shop/:id", component: () => import("../views/ShopInner"), children: [{
    path: "", component: () => import("../views/ShopView")
  }]
}];

const routes = [{
  path: "", component: Layout, children: [{
    path: "", component: HomeView, name: "home"
  }]
}, {
  path: "/:lang", component: Layout, children: route
}];

const router = new VueRouter({
  mode: "history", base: process.env.BASE_URL, routes
});

function updateScroll() {
  let scroll = store.state.scroll._scroll;
  scroll.scrollTo(-1800, {
    duration: .1, disableLerp: true
  });
  scroll?.update();
}

function initLoco() {
  if (window.innerWidth <= 1023) return;
  const Loco = new LocomotiveScroll({
    el: document.querySelector("[data-scroll-container]"), direction: "vertical",

    // multiplier: 1.1,
    smooth: true, touchMultiplier: 2.5, lerp: 0.06, gestureDirection: "vertical", tablet: {
      smooth: true, direction: "vertical", breakpoint: 1024
    }, smartphone: {
      breakpoint: 767, smooth: false, direction: "vertical"
    }
  });
  store.dispatch("scroll/setScroll", Loco);
}

function scrollTrigger() {
  let scroll = store.state.scroll._scroll;
  scroll ? updateScroll() : initLoco();
  document.querySelector("#app").scrollTop = 0;
}

router.beforeEach(async (to, from, next) => {
  const token = localStorage.getItem("token") || false;
  if (to.meta.requiresAuth && !token) {
    next({ name: "home" });
  }
  if (to.path === from.path) {
    setTimeout(() => {
      try {
        scrollTrigger();
        store.dispatch("loader/setLoader", false);
      } catch (e) {
        console.log(e);
      }
    }, 1500);
    next();
  } else {
    if (!(to.path?.toLowerCase()?.includes("shop") && from.path?.toLowerCase()?.includes("shop"))) {
      store.dispatch("loader/setLoader", true);
    }
    setTimeout(() => {
      try {
        scrollTrigger();
        store.dispatch("loader/setLoader", false);
      } catch (e) {
        console.log(e);
      }
    }, 1500);

    setTimeout(() => {
      next();
    }, 400);
  }

});

export default router;

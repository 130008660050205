<template>
  <div class="loader">
    <logo />
  </div>
</template>

<script>
import Logo from "@/components/base/Logo";

export default {
  components: {
    Logo
  }
};
</script>

<style lang="scss" scoped>
$ease: cubic-bezier(.22, -0.01, .34, 1);

::v-deep.loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: black;
  z-index: 999999;
  display: flex;
  align-items: center;
  justify-content: center;

  .logo {
    width: 300px;
  }

  svg {

    path {
      fill: white !important;
    }
  }
}
</style>
import Vue from "vue";
import store from "@/store";

let $vm = Vue.prototype;

export default {
  namespaced: true,
  state: () => ({
    _contact: []
  }),
  actions: {
    getContact({ commit }) {
      return $vm.$axios.get(`/getContact/${store.getters["lang/activeLang"]}`)
        .then(({ data }) => {
          commit("SET_CONTACT", data.contact);
        });
    }
  },
  mutations: {
    SET_CONTACT(state, data) {
      state._contact = data;
    }
  },
  getters: {
    contact(state) {
      return state._contact;
    }

  }
};

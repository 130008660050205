<template>
   <div class="popup_window">
      <div class="popup_inner">
         <div class="popup_close-btn is-absolute" @click="onClose"></div>
         <forgot @onChnageTab="$emit('onChnageTab', 'signin')"/> 
      </div>
   </div>
</template>

<script>
import Forgot from './forgot/Forgot.vue';
export default {
   name: 'ForgotPassword',
   components:{
      Forgot
   },
   methods: {
      onClose() {
         this.$store.commit('toggleAuthModal');
      }
   } 
}
</script>

<style lang="scss" scoped>
.popup_inner{
   padding-top: 20px;
}
</style>
<template>
  <div class="w-form" :class="checkout ? 'checkout_form-wrapper' : 'form_block'">
    <form id="wf-form-Sign-up-form" name="wf-form-Sign-up-form" data-name="Sign up form" method="get" class="form"
          @submit.prevent>
      <h2 class="form_heading-small">{{ $tr('Sign Up form') }}</h2>
      <div class="form-fields">
        <input type="text" class="text-field w-input" maxlength="256" name="Name" data-name="Name"
               :placeholder="$tr('Full name')" id="Name-2" v-model="name">
        <input type="number" class="text-field w-input" maxlength="256" name="ID" data-name="ID"
               :placeholder="$tr('ID number')" id="ID" v-model="id_number">
        <input type="number" class="text-field is-phone w-input" maxlength="256" name="phone-2" data-name="Phone 2"
               :placeholder="$tr('Phone')" id="phone-2" v-model="phone_number">
        <input type="email" class="text-field is-email w-input" maxlength="256" name="email-2" data-name="Email 2"
               :placeholder="$tr('Email')" id="email-2" v-model="email">
        <input type="password"
               class="text-field is-password w-node-_72720e62-4831-02bf-270f-3cce271a0d14-f61dcf5e w-input"
               maxlength="256"
               :placeholder="$tr('Password')" required="" v-model="password">
        <input type="password"
               class="text-field is-password w-node-_72720e62-4831-02bf-270f-3cce271a0d14-f61dcf5e w-input"
               maxlength="256"
               :placeholder="$tr('Repeat Password')" required="" v-model="re_password">

        <label class="w-checkbox from_checkbox-field ">
          <div class="w-checkbox-input w-checkbox-input--inputType-custom form_checkbox"
               :class="{ ' w--redirected-checked': agree }"></div>
          <input type="checkbox" id="Argeement" name="Argeement" data-name="Argeement" required=""
                 style="opacity:0;position:absolute;z-index:-1" v-model="agree">
          <span style="display: flex;align-items: center" class="w-form-label" for="Argeement">
                  {{ $tr('I agree that I have read and understood Dodo Designs') }}
            <!--                  <router-link :to="{ name: 'Terms' }" class="grey-link">-->
            <!--                     {{ $tr('terms conditions.') }} -->
            <!--                  </router-link>-->
                       <terms style="margin-left: 5px"/>
               </span>
            </label>
         </div>
         <input type="submit" :value="$tr('create account')" :data-wait="$tr('Please wait...')" class="btn w-button"
            :style="{ 'opacity': !agree ? 0.5 : 1 }" @click.prevent="onRegister" :disabled="!agree">
      </form>
      <transition name="fade">
         <div v-if="success" class="form_success">
            <div class="form_success-inner">
               <h2 class="form_success-heading"> {{ $tr('You successfully registered on a page!') }}  </h2>
               <div>
                  {{ $tr('Create your account below and place orders online, manage campaigns, check your expenses andmore') }} 
               </div>
               <img src="@/assets/images/registration.webp"
                     alt="Done"
                  class="form_success-img">
               <div class="form_success-btn-wrapper">
                  <span class="contact_form-btn pointer" @click="onSuccess">{{ $tr('Okay') }}</span>
               </div>
            </div>
         </div>
      </transition>
      <transition name="fade-up">
         <div v-if="error" class="w-form-fail block">
            <div v-for="err in error_messages" :key="err">{{ $tr(err) }}</div>
         </div>
      </transition>
   </div>
</template> 

<script>
import { useVuelidate } from '@vuelidate/core';
import { required, email, minLength, maxLength, helpers, sameAs } from '@vuelidate/validators';
import Terms from '../../components/base/Terms';

export default {
  name: 'SignupForm',
  props: {
    checkout: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Terms
  },
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      name: '',
      id_number: '',
      phone_number: '',
      email: '',
      password: '',
      re_password: '',
      agree: false,
      error: false,
      error_messages: [],
      success: false
    };
  },
  methods: {
    check() {
      return new Promise((resolve, reject) => {
        if (!this.v$.$silentErrors.length) resolve();
        else {
          this.v$.$silentErrors.forEach((err) => this.error_messages.push(err.$message));
          this.error = true;
        }
      });
    },
    async onRegister() {
      this.error = false;
      this.error_messages = [];
      const user = {
        email: this.email,
        password: this.password,
        name: this.name,
        id_number: this.id_number,
        phone_number: this.phone_number,
        lang: this.$store.state.lang._activeLang
      };
      await this.check();
      if (this.checkout) {
        this.$store.dispatch('registerInCheckout', user)
            .then(() => {
              this.$router.push({ path: this.$route.path, query: { step: 'delivery' } });
            })
            .catch((err) => {
              this.error_messages.push(err.data.message);
              this.error = true;
            });
      } else {
        this.$store.dispatch('register', user)
            .then(() => {
              this.success = true;
            })
            .catch((err) => {
              this.error_messages.push(err.data.message);
              this.error = true;
            });
      }
    },
    onSuccess() {
      this.$store.commit('toggleAuthModal');
      this.success = false;
      this.name = '';
      this.id_number = '';
      this.phone_number = '';
      this.email = '';
      this.password = '';
      this.re_password = '';
      this.agree = false;
    }
  },
  validations() {
    return {
      email: {
        required: helpers.withMessage('email is requred', required),
        email: helpers.withMessage('please enter valid email', email)
      },
      password: {
        required: helpers.withMessage('password is requred', required),
        minLength: helpers.withMessage('password min length is 8', minLength(8))
      },
      re_password: {
        sameAs: helpers.withMessage('please enter same password', sameAs(this.password))
      },
      name: { required: helpers.withMessage('name is requred', required) },
      id_number: {
        required: helpers.withMessage('id is requred', required),
        minLength: helpers.withMessage('id number length should be 11', minLength(11)),
        maxLength: helpers.withMessage('id number length should be 11', maxLength(11))
      },
    };
  }
};
</script>

<style scoped>
.checkout_form-wrapper {
  position: relative;
}

.pointer {
  cursor: pointer;
}

.form_success {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff;
}

.w-checkbox {

  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}
</style>
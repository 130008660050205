import Vue from "vue";
import store from "@/store";

let $vm = Vue.prototype;

export default {
  namespaced: true,
  state: () => ({
    _slider: []
  }),
  actions: {
    getSlider({ commit }) {
      return $vm.$axios.get(`/getSliderContents/${store.getters["lang/activeLang"]}`)
        .then(({ data }) => {
          commit("SET_SLIDER", data.sliderContents);
        });
    }
  },
  mutations: {
    SET_SLIDER(state, data) {
      state._slider = data;
    }
  },
  getters: {
    slider(state) {
      return state._slider;
    }

  }
};

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "./helperes/api";
import SlideUpDown from "vue-slide-up-down";
import tr from './helperes/tr'
import VueMeta from 'vue-meta'
Vue.use(VueMeta)

Vue.prototype.$tr = tr;

Vue.component("slide-up-down", SlideUpDown);
Vue.config.productionTip = false;
Vue.use(axios);
import MouseTypes from "./mixins/MouseTypes";

import PortalVue from "portal-vue";

Vue.use(PortalVue);
Vue.mixin(MouseTypes);
new Vue({
  router,
  store,
  render: h => h(App),
  created() {
    // Call the global function after store initialization and language setup
    setTimeout(() => {
      this.$tr();
    }, 1500)
  }
}).$mount('#app');


import Vue from "vue";
let $vm = Vue.prototype;

export default {
   state: () => ({
      countries: [],
      cities: []
   }),
   mutations: {
      setContries(state, countries){
         state.countries = countries;
      },
      setCities(state, cities){
         state.cities = cities;
      }
   },
   getters: {

   },
   actions: {
      getContries({commit, rootState}){
         return new Promise((resolve, reject) => {
            $vm.$axios.get(`delivery/getCountries/${rootState.lang._activeLang}`)
            .then((response) => {
               commit('setContries', response.data?.countries || []);
               resolve(response);
            })
            .catch((err) => {
               reject(err);
            })
         })
      },
      getCities({rootState}, country){
         return new Promise((resolve, reject) => {
            $vm.$axios.get(`delivery/getCities/${country + '/' + rootState.lang._activeLang}`)
            .then((response) => {
               resolve(response);
            })
            .catch((err) => {
               reject(err);
            })
         })
      }
   }
}
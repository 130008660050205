<template>
  <div ref="cursor" class="custom-cursor">
    <div class="landing-handler handler"></div>
    <div class="image-scale-handler handler"
         :style="{ borderColor: color ? color : 'rgba(250,250,250,.5)' }">
      <svg class="icon" width="20" height="20" viewBox="0 0 20 20">
        <g
          id="Group_55"
          data-name="Group 55"
          transform="translate(1.446 2.966)"
        >
          <g
            id="Group_74"
            data-name="Group 74"
            transform="translate(-0.5 0.5)"
          >
            <line
              id="Line_5"
              data-name="Line 5"
              x2="18"
              transform="translate(0.054 6.534)"
              fill="none"
              :stroke="color ? color : '#fff'"
              stroke-linecap="round"
              stroke-width="2"
            />
            <line
              id="Line_15"
              data-name="Line 15"
              y2="18"
              transform="translate(9.054 -2.466)"
              fill="none"
              :stroke="color ? color : '#fff'"
              stroke-linecap="round"
              stroke-width="2"
            />
          </g>
        </g>
      </svg>
    </div>
    <div class="image-down-handler handler">
      <svg
        class="icon"
        width="15.556"
        height="15.556"
        viewBox="0 0 15.556 15.556"
      >
        <g
          id="Group_55"
          data-name="Group 55"
          transform="translate(7.778 -4.95) rotate(45)"
        >
          <g id="Group_74" data-name="Group 74">
            <line
              id="Line_5"
              data-name="Line 5"
              x2="18"
              transform="translate(0 9)"
              fill="none"
              stroke="#E5B676"
              stroke-linecap="round"
              stroke-width="2"
            />
            <line
              id="Line_15"
              data-name="Line 15"
              y2="18"
              transform="translate(9)"
              fill="none"
              stroke="#E5B676"
              stroke-linecap="round"
              stroke-width="2"
            />
          </g>
        </g>
      </svg>
    </div>
    <div class="video-scale-handler handler">
      <svg
        class="icon"
        width="40.759"
        height="22.708"
        viewBox="0 0 40.759 22.708"
      >
        <g
          id="Group_55"
          data-name="Group 55"
          transform="translate(1.414 1.414)"
        >
          <line
            id="Line_3"
            data-name="Line 3"
            x2="19.005"
            y2="19.881"
            transform="translate(18.926 0)"
            fill="none"
            stroke="#fff"
            stroke-linecap="round"
            stroke-width="2"
          />
          <line
            id="Line_4"
            data-name="Line 4"
            x1="18.927"
            y2="19.881"
            transform="translate(0 0)"
            fill="none"
            stroke="#fff"
            stroke-linecap="round"
            stroke-width="2"
          />
          <line
            id="Line_5"
            data-name="Line 5"
            x2="38"
            transform="translate(0.027 19.881)"
            fill="none"
            stroke="#fff"
            stroke-linecap="round"
            stroke-width="2"
          />
        </g>
      </svg>
    </div>
    <div class="explore-handler handler">
      <span class="title">Explore</span>
    </div>
    <div class="slider-handler handler">
      <svg
        class="icon"
        width="16.791"
        height="18.33"
        viewBox="0 0 16.791 18.33"
      >
        <g
          id="Group_55"
          data-name="Group 55"
          transform="translate(1.414 1.414)"
        >
          <line
            id="Line_3"
            data-name="Line 3"
            x2="6.996"
            y2="7.318"
            transform="translate(6.967 0)"
            fill="none"
            stroke="#E5B676"
            stroke-linecap="round"
            stroke-width="2"
          />
          <line
            id="Line_4"
            data-name="Line 4"
            x1="6.967"
            y2="7.318"
            transform="translate(0 0)"
            fill="none"
            stroke="#E5B676"
            stroke-linecap="round"
            stroke-width="2"
          />
          <line
            id="Line_5"
            data-name="Line 5"
            y2="14.893"
            transform="translate(6.981 1.023)"
            fill="none"
            stroke="#E5B676"
            stroke-linecap="round"
            stroke-width="2"
          />
        </g>
      </svg>
    </div>
    <div class="wine-collection-handler handler"
         :style="{ backgroundColor: color }">
      <div
        :class="{ active: wineCollectionDirection > 0 }"
        class="prev cont"
      >
        <span class="title">Prev</span>
        <svg
          class="icon"
          width="22.23"
          height="11.775"
          viewBox="0 0 22.23 11.775"
        >
          <g
            id="Group_55"
            data-name="Group 55"
            transform="translate(0.5 11.068) rotate(-90)"
          >
            <line
              id="Line_3"
              data-name="Line 3"
              y1="5.431"
              x2="5.192"
              transform="translate(5.17 15.593)"
              fill="none"
              stroke="#fff"
              stroke-linecap="round"
              stroke-width="1"
            />
            <line
              id="Line_4"
              data-name="Line 4"
              x1="5.17"
              y1="5.431"
              transform="translate(0 15.593)"
              fill="none"
              stroke="#fff"
              stroke-linecap="round"
              stroke-width="1"
            />
            <line
              id="Line_5"
              data-name="Line 5"
              y1="20.265"
              transform="translate(5.181 0)"
              fill="none"
              stroke="#fff"
              stroke-linecap="round"
              stroke-width="1"
            />
          </g>
        </svg>
      </div>
      <div
        :class="{ active: wineCollectionDirection < 0 }"
        class="next cont"
      >
        <span class="title">Next</span>
        <svg
          class="icon"
          width="22.23"
          height="11.775"
          viewBox="0 0 22.23 11.775"
        >
          <g
            id="Group_55"
            data-name="Group 55"
            transform="translate(0.5 11.068) rotate(-90)"
          >
            <line
              id="Line_3"
              data-name="Line 3"
              y1="5.431"
              x2="5.192"
              transform="translate(5.17 15.593)"
              fill="none"
              stroke="#fff"
              stroke-linecap="round"
              stroke-width="1"
            />
            <line
              id="Line_4"
              data-name="Line 4"
              x1="5.17"
              y1="5.431"
              transform="translate(0 15.593)"
              fill="none"
              stroke="#fff"
              stroke-linecap="round"
              stroke-width="1"
            />
            <line
              id="Line_5"
              data-name="Line 5"
              y1="20.265"
              transform="translate(5.181 0)"
              fill="none"
              stroke="#fff"
              stroke-linecap="round"
              stroke-width="1"
            />
          </g>
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import gsap from "gsap";

export default {
  props: {
    callback: {
      type: Function,
      required: false
    }
  },
  data() {
    return {
      scaleTimeout: null,
      mouseTimeline: null,
      wineCollectionDirection: 0,
      stuckTimeLine: null
    };
  },
  computed: {
    ...mapGetters({
      cursorEvent: "mouseCursor/getEvent",
      mouseType: "mouseCursor/getMouseType",
      mouseTarget: "mouseCursor/getMouseTarget",
      color: "mouseCursor/getColor"
    }),
    activeMouseTypeHandler() {
      return this[`mouse${this.mouseType}Handler`];
    },
    position() {
      let stuckEl = this.mouseTarget.querySelector(".stuck-area");

      return stuckEl?.getBoundingClientRect();
    },
    cursorSize() {
      return {
        width: 24,
        height: 24
      };
    }
  },
  methods: {
    ...mapActions({
      setPosition: "mouseCursor/setPosition"
    }),
    defaultMove(event, colored) {
      if (this.scaleTimeout) clearTimeout(this.scaleTimeout);
      let { width, height } = this.cursorSize;
      let x = event.x - width / 2;
      let y = event.y - height / 2;
      gsap.to(".custom-cursor", {
        x,
        y,
        backgroundColor: "transparent",
        borderColor: colored ? "rgba(250,250,250)" : "transparent",
        mixBlendMode: "unset",
        overwrite: true
      });
      gsap.to(".custom-cursor", {
        scale: 0,
        duration: 2.5,
        overwrite: "auto"
      });

      this.scaleTimeout = setTimeout(() => {
        gsap.to(".custom-cursor", {
          x,
          y,
          scale: 1,
          backgroundColor: "transparent",
          borderColor: colored ? "rgba(250,250,250)" : "transparent",
          mixBlendMode: "unset",
          // ease: 'power1.out',
          overwrite: true
        });
      }, 1);
    },
    resetAllHandler() {
      if (this.stuckTimeLine) {
        this.stuckTimeLine.pause();
        this.stuckTimeLine.clear();
        // let animated = this.mouseTarget.querySelector(".animate");
        //
        // if (!animated) return;
        // gsap.to(animated, {
        //   x: 0,
        //   y: 0,
        //   duration: 0.8,
        //   overwrite: true,
        //   ease: this.CustomEase.create(
        //     "custom",
        //     "M0,0,C0.066,0.298,0.202,1,1,1"
        //   )
        // });
      }

      gsap.to(".custom-cursor .handler", {
        opacity: 0
      });
      gsap.to(".custom-cursor", {
        backgroundColor: "transparent",
        borderColor: "transparent",
        mixBlendMode: "unset"
      });
    },
    mouseLandingHandler(event) {
      this.defaultMove(event);
      gsap.to(".landing-handler", {
        opacity: 1
      });
    },
    mouseStuckHandler(event) {
      if (this.scaleTimeout) clearTimeout(this.scaleTimeout);

      let radius = (this.cursorSize.width - this.position.width) / 2;
      this.stuckTimeLine = gsap.timeline();

      let xDelta =
        (this.position.left + this.position.width / 2 - event.clientX) /
        3;
      let yDelta =
        (this.position.top + this.position.width / 2 - event.clientY) /
        3;
      let x = this.position.left - radius - xDelta;
      let y = this.position.top - radius - yDelta;
      let animated = this.mouseTarget.querySelector(".animate");

      this.stuckTimeLine.to(".custom-cursor", {
        x,
        y,
        scale: 4,
        background: "white",
        mixBlendMode: "difference",
        duration: 0.4,
        overwrite: true,
        ease: "Power1.easeOut"
      });

      if (!animated) return;
      gsap.to(animated, {
        x: -xDelta,
        y: -yDelta,
        duration: 0.8,
        overwrite: true,
        ease: this.CustomEase.create(
          "custom",
          "M0,0,C0.066,0.298,0.202,1,1,1"
        )
      });
    },
    mouseDefaultHandler(event) {
      this.defaultMove(event);
    },
    mouseCustomHandler(event) {
      this.defaultMove(event, true);
    },
    mouseImageScaleHandler(event) {
      this.defaultMove(event);
      gsap.to(".image-scale-handler", {
        opacity: 1
      });
    },
    mouseImageDownHandler(event) {
      this.defaultMove(event);
      gsap.to(".image-down-handler", {
        opacity: 1
      });
    },
    mouseVideoScaleHandler(event) {
      this.defaultMove(event);
      gsap.to(".video-scale-handler", {
        opacity: 1
      });
    },
    mouseVideoDownHandler(event) {
      if (event.clientY > innerHeight - 150) {
        this.resetAllHandler();
        this.mouseDefaultHandler(event);
      } else {
        this.defaultMove(event);
        gsap.to(".image-down-handler", {
          opacity: 1
        });
      }
    },
    mouseExploreHandler(event) {
      this.defaultMove(event);
      gsap.to(".explore-handler", {
        opacity: 1
      });
    },
    mouseWineCollectionHandler(event) {
      this.defaultMove(event);
      gsap.to(".wine-collection-handler", {
        opacity: 1
      });
      if (event.clientX > innerWidth / 2) {
        this.wineCollectionDirection = -1;
      } else {
        this.wineCollectionDirection = 1;
      }
    },
    mouseSliderHandler(event) {
      this.defaultMove(event);
      gsap.to(".slider-handler", {
        opacity: 1
      });
      if (event.clientX > innerWidth / 2) {
        gsap.to(".slider-handler .icon", {
          transform: "rotate(90deg)"
        });
      } else {
        gsap.to(".slider-handler .icon", {
          transform: "rotate(-90deg)"
        });
      }
    },
    mouseHeaderHandler(event) {
      this.defaultMove(event);
      // this.resetAllHandler()
      gsap.to(".header-handler", {
        opacity: 1
      });

      // var body = document.body;

      // body.classList.add("pointer");
    },
    mouseBlendModeHandler(event) {
      let { width, height } = this.cursorSize;

      gsap.to(".custom-cursor", {
        x: event.x - width / 2,
        y: event.y - height / 2,
        mixBlendMode: "difference",
        background: "white",
        scale: 4
      });
    },
    mouseMove(event) {
      this.activeMouseTypeHandler(event);
      this.setPosition(event);
      if (typeof this.callback === "function") this.callback(event);
    },
    mouseMoveEventHandler() {
      window.addEventListener("mousemove", this.mouseMove, {
        passive: true
      });
    }
  },
  watch: {
    mouseType: {
      handler: function() {
        this.resetAllHandler();
        // if (this.mouseType !==  this.mouseTypes.Default) {
        // }
      }
    }
  },
  created() {
    this.mouseMoveEventHandler();
  },
  beforeDestroy() {
    window.removeEventListener("mousemove", this.mouseMove);
  }
};
</script>

<style lang="scss">
.custom-cursor {
  border-radius: 50%;
  //border: 1px solid rgba(250, 250, 250, .5);
  width: 24px;
  height: 24px;
  position: absolute;
  z-index: 99;
  pointer-events: none;
  left: 0;
  top: 0;
  border: 1px solid transparent;
  @media (max-width: 1023px) {
    display: none;
  }

  .landing-handler {
    width: 100%;
    height: 100%;
    background: #FEAC58;
    border-radius: 50%;
  }

  .handler {
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    opacity: 0;
    position: absolute;
  }

  .header-handler {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: white;

    .title {
      color: #e5b676;
    }

    .icon {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 15px;
      display: block;
    }
  }

  .image-scale-handler {
    width: calc(100% + 30px);
    height: calc(100% + 30px);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border: 2px solid rgba(250, 250, 250, .5);
    background: transparent;
  }

  .image-down-handler {
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border: 2px solid rgba(255, 255, 255, 0.3);
  }

  .video-scale-handler {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid rgba(193, 148, 50, 0.9);
    border-radius: 50%;
    background: transparent;

    .icon {
      transform: rotate(90deg);
      @media only screen and (max-width: 1365px) and (min-width: 1024px) {
        width: 38px;
      }
    }
  }

  .explore-handler {
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
    border-radius: 50%;

    .title {
      color: #e5b676;
    }
  }

  .wine-collection-handler {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    color: white;

    .prev,
    .next {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      flex-direction: column;
      opacity: 0;
      transition: opacity 0.4s ease-out;

      &.active {
        opacity: 1;
      }
    }

    .icon {
      margin-top: 4px;
    }

    .prev {
      align-items: flex-start;

      .icon {
        transform: rotate(180deg);
      }
    }

    .next {
      align-items: flex-end;
    }
  }

  .slider-handler {
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border: 2px solid rgba(255, 255, 255, 0.3);
    background: transparent;

    .icon {
      transform: rotate(90deg);
    }
  }

  .blend-mode-handler {
    width: 60px;
    height: 60px;
    mix-blend-mode: difference;
    background: white;
  }
}
</style>

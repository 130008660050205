import Vue from "vue";
import store from "@/store";

let $vm = Vue.prototype;

export default {
  namespaced: true, state: () => ({
    _projects: [],
    _projectsCategory: []
  }), actions: {
    getProjects({ commit }, props) {
      return $vm.$axios.get(`/getProjects/${store.getters["lang/activeLang"]}`, { params: props })
        .then(({ data }) => {
          commit("SET_PROJECTS", data);
        });
    },
    getProjectsCategory({ commit }) {
      return $vm.$axios.get(`/getProjectCategories/${store.getters["lang/activeLang"]}`)
        .then(({ data }) => {
          commit("SET_PROJECTS_CATEGORY", data);
        });
    }
  }, mutations: {
    SET_PROJECTS(state, data) {
      state._projects = data.projects;
    },
    SET_PROJECTS_CATEGORY(state, data) {
      state._projectsCategory = data.projectCategories;
    }
  }, getters: {
    projects(state) {
      return state._projects;
    },
    projectsCategory(state) {
      return state._projectsCategory;
    }

  }
};

<template>
   <transition name="popup">
      <div v-if="is_open" class="popup">
         <div class="popup_bg is-dark"></div>
         <div class="popup_window">
            <div class="cart_header">
               <h2 class="popup_heading">{{$tr('Shopping Cart')}}</h2>
               <div class="popup_close-btn is-absolute" @click="$store.commit('toggleCartModal')"></div>
               <p v-if="false">
                  {{ $tr('Vestibulum pulvinar lectus et magna suscipit, ut sollicitudin massa convallis.')}}
               </p>
               <transition name="fade-up">
                  <div v-if="error" class="w-form-fail block">
                     <div>{{ $tr(error) }}</div>
                  </div>
               </transition>
               <div class="cart_divider"></div>
               <div class="cart_items-quantity-line">{{ length }} {{ $tr('items in THE cart')}}</div>
               <div class="cart_divider"></div>
            </div>
            <div class="cart_items-wrapper">
               <div class="cart_items-list">
                  <cart-item v-for="product in products" :key="product.id" :product="product" />
               </div>
               <div class="cart_bottom">
                  <div class="cart_price-wrapper">
                     <div class="cart_subtotal-heading">{{ $tr('Subtotal')}}</div>
                     <div class="cart_price">{{ total }} {{ $tr('GEL')}} </div>
                  </div>
                  <span class="btn is-checkout pointer" @click="onCheckout">{{ $tr('Checkout')}}</span>
               </div>
            </div>
         </div>
      </div>
   </transition>
</template>

<script>
import CartItem from './CartItem.vue';
export default {
   name: 'Cart',
   components: {
      CartItem
   },
   computed: {
      is_open() {
         return this.$store.state.cart.is_open;
      },
      products() {
         return this.$store.state.cart.cart
      },
      total() {
         return this.products.reduce((accumulator, product) => {
            return accumulator + Number(product.price);
         }, 0)
      },
      length() {
         return this.products.length
      },
      error() {
         return this.$store.state.cart.error;
      },
      lang() {
         return this.$store.state.lang._activeLang;
      }
   },
   methods: {
      onCheckout() {
         this.$route.name != 'Checkout' ? this.$router.push({ path: `/${this.lang}/checkout` }) : '';
         this.$store.commit('toggleCartModal');
      }
   }
}
</script>
 
<style lang="scss" scoped> 
.cart_items-wrapper::-webkit-scrollbar {
   width: 6px;
}

.cart_items-wrapper::-webkit-scrollbar-track {
   background: #BCC1C2;
   margin-bottom: 34px;
   border: solid 2px white;
}

.cart_items-wrapper::-webkit-scrollbar-thumb {
   background: #FEAC58;
   border-radius: 1px;
}
.w-form-fail{
   width: 100%;
}
</style>
<template>
  <footer class="footer">
    <div class="footer_top">
      <div id="w-node-e6e68f36-678b-0d09-6158-e8aee5dd269a-e5dd2698" class="footer_top-left">
        <a href="index.html" class="footer_logo-link w-inline-block">
          <img src="@/assets/images/Logo.svg" loading="lazy"
               width="154" height="31" alt="Logo"></a>
        <ul role="list" class="footer_social-list w-list-unstyled">
          <li class="footer_social-item">
            <a :href="socialInfo.ins" target="_blank" class="footer_social-link w-inline-block">
              <div class="footer_social-link-icon-wrapper">
                <img src="@/assets/images/instagram-white.svg" loading="lazy"
                     width="11" height="11" alt="Imstagram"
                     class="footer_social-link-icon"></div>
              <div class="footer_social-link-text">{{ $tr('Instagram') }}</div>
            </a>
          </li>
          <li class="footer_social-item">
            <a :href="socialInfo.fb" target="_blank" class="footer_social-link w-inline-block">
              <div class="footer_social-link-icon-wrapper">
                <img src="@/assets/images/facebook-single.svg" loading="lazy"
                     width="6" height="11" alt="Facebook"></div>
              <div class="footer_social-link-text">{{ $tr('Facebook') }}</div>
            </a>
          </li>
          <li class="footer_social-item">
            <a href="#" target="_blank" class="footer_social-link w-inline-block">
              <img src="@/assets/images/Behance.svg"
                   loading="lazy" width="21"
                   height="21" alt="Behance"
                   class="footer_social-link-icon">
              <div class="footer_social-link-text">{{ $tr('Behance') }}</div>
            </a>
          </li>
        </ul>
      </div>
      <div id="w-node-e6e68f36-678b-0d09-6158-e8aee5dd26af-e5dd2698" class="footer_top-right">
        <h2 class="footer_heading">{{ $tr('CONTACT US') }}</h2>
        <p><strong>{{ contactInfo.address }}<br></strong>{{ contactInfo.email }}   {{ $tr('Phone:') }}
          {{ contactInfo.phone }}</p>
      </div>
    </div>
    <div class="footer_bottom">
      <div id="w-node-e6e68f36-678b-0d09-6158-e8aee5dd26b8-e5dd2698" class="footer_bottom-left">
        <p>{{ $tr(' 2022 © Dodo Designs.') }}</p>
        <div class="footer_legal-links">
          <TermsFooter></TermsFooter>
        </div>
      </div>
      <div id="w-node-e6e68f36-678b-0d09-6158-e8aee5dd26c1-e5dd2698" class="footer_bottom-right">
        <p> {{ $tr('Design by') }} <a href="https://palindroma.ge/" target="_blank" class="footer_bottom-link">{{ $tr('Palindroma') }} </a>
        </p>
      </div>
    </div>
  </footer>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import TermsFooter from '../components/base/TermsFooter.vue';

export default {
  components: {
    TermsFooter
  },
  computed: {
    ...mapGetters({
      contact: "contact/contact"
    }),
    contactInfo() {
      return this.contact?.contact || {};
    },
    socialInfo() {
      return this.contact?.social || {};
    },
  },

  methods: {
    ...mapActions({
      getContact: "contact/getContact"
    }),
  },
  created() {
    this.getContact();
  }
};
</script>
<template>
  <div :class="{white:showHeader}" class="home-footer">
    <div class="item">
      <span>{{$tr('c')}}</span>
    </div>
    <div class="social-items">
      <div class="item">
        <svg id="Bold" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
             x="0px" y="0px"
             viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
          <path class="st0" d="M256.1,124.5c-72.6,0-131.4,58.8-131.4,131.4c0,72.6,58.8,131.4,131.4,131.4c72.6,0,131.4-58.8,131.4-131.4
	C387.5,183.3,328.6,124.5,256.1,124.5L256.1,124.5z M256.1,341.2c-47.1,0-85.3-38.2-85.3-85.3s38.2-85.3,85.3-85.3
	s85.3,38.2,85.3,85.3C341.4,303,303.2,341.2,256.1,341.2z" />
          <path class="st0" d="M361.6,1.6c-47.1-2.2-163.8-2.1-210.9,0C109.2,3.6,72.7,13.6,43.2,43C-6,92.3,0.3,158.6,0.3,255.9
	c0,99.6-5.5,164.4,42.9,212.9c49.4,49.4,116.7,42.9,212.9,42.9c98.6,0,132.7,0.1,167.6-13.4c47.4-18.4,83.2-60.8,86.7-136.9
	c2.2-47.1,2.1-163.8,0-210.9C506.2,60.6,457.9,6.1,361.6,1.6L361.6,1.6z M436.1,436.2c-32.3,32.3-77.1,29.4-180.7,29.4
	c-106.7,0-149.4,1.6-180.7-29.7c-35.9-35.8-29.4-93.2-29.4-180.3c0-117.9-12.1-202.8,106.2-208.8c27.2-1,35.2-1.3,103.6-1.3l1,0.6
	c113.7,0,202.9-11.9,208.2,106.4c1.2,27,1.5,35.1,1.5,103.4C465.8,361.3,467.8,404.4,436.1,436.2L436.1,436.2z" />
          <circle class="st0" cx="392.7" cy="119.4" r="30.7" />
</svg>
      </div>
      <div class="item">
        <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
             viewBox="0 0 73 145.7" style="enable-background:new 0 0 73 145.7;" xml:space="preserve">
          <path class="st0" d="M54.3,27.7H73V0H51v0.1C24.2,1,18.7,16.1,18.3,31.9h-0.1v13.8H0v27.2h18.2v72.8h27.4V72.9h22.5l4.3-27.2H45.7
	v-8.4C45.7,32,49.2,27.7,54.3,27.7z" />
</svg>
      </div>
      <div class="item">
        <svg id="Capa_1"
             xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
             x="0px" y="0px"
             viewBox="0 0 335 272.1" style="enable-background:new 0 0 335 272.1;" xml:space="preserve">
          <path class="st0" d="M0,241.8c30.3,19.2,66.3,30.4,104.9,30.4c108.3,0,196.2-87.8,196.2-196.2c0-2.7-0.1-5.4-0.2-8.1
	c3.9-3.1,28.2-22.5,34.1-35c0,0-19.7,8.2-38.9,10.1c0,0-0.1,0-0.1,0c0,0,0,0,0.1-0.1c1.8-1.2,26.6-18.1,30-38.2
	c0,0-13.9,7.4-33.4,13.9c-3.2,1.1-6.6,2.1-10.1,3.1C269.9,8.4,252.1,0,232.2,0c-38,0-68.8,30.8-68.8,68.8c0,5.4,0.6,10.6,1.8,15.6
	c-5.3-0.2-86.2-4.8-141.3-71.4c0,0-32.9,44.9,19.6,91.1c0,0-16-0.6-29.7-8.9c0,0-5.1,54.4,54.4,68.3c0,0-11.7,4.4-30.4,1.3
	c0,0,10.4,44,63.3,48.1C101.1,212.9,59.3,250.6,0,241.8L0,241.8z" />
</svg>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters({
      showHeader: "header/getShowHeader"
    })
  }
};
</script>

<style lang="scss" scoped>
$ease: cubic-bezier(.3, -0.01, 0, .99);

.home-footer {
  position: fixed;
  bottom: 55px;
  width: calc(100% - 160px);
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  left: 50%;
  transform: translateX(-50%);
  z-index: 9;

  @media only screen and (max-width: 1650px) and (min-width: 1280px) {
    width: calc(100% - 120px);
  }
  @media only screen and (max-width: 1279px) and (min-width: 1024px) {
    width: calc(100% - 80px);
  }
  @media only screen and (max-width: 1023px) {
    display: none;
  }

  &.white {
    .item {
      background: white;
      color: #0F0F0F;

      svg {
        path {
          fill: #0F0F0F;
        }
      }
    }
  }

  .item {
    width: 21px;
    height: 21px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    cursor: pointer;
    transition: opacity $ease .55s;
    background: #0F0F0F;
    margin-top: 15px;
    color: white;

    &:first-child {
      margin-top: 0;
    }

    span {
      height: 10px;
      display: inline-block;
      line-height: .3;
    }

    &:hover {
      opacity: .6;
    }

    svg {
      width: 10px;
      height: 10px;

      path {
        fill: white;
      }
    }
  }
}
</style>
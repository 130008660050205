import axios from "axios";
import store from "@/store";
import Vue from "vue";

let url = "https://cms.dododesigns.ge/api";

const devInstance = createInstance(url);

function signOut() {

}

async function refreshedToken() {
}

function createInstance(baseURL) {
  return axios.create({
    baseURL,
    headers: {
      "Content-Type": "application/json",
      "Accept": "application/json"
    }
  });
}
devInstance.interceptors.request.use(function (config) {
  const token = localStorage.getItem("token") || '';
  config.headers.Authorization =  'bearer' + token;
  return config;
});

devInstance.interceptors.response.use(
  response => {
    if (response.status === 200 || response.status === 201) {
      return Promise.resolve(response);
    } else {
      return Promise.reject(response);
    }
  },
  error => {
    const originalConfig = error.config;
    if (error.response?.status === 401) {
      store.dispatch('logout'); 
    }  
    if (error.response?.status) {
      return Promise.reject(error.response);
    }
  });

devInstance.interceptors.request.use(config => {
  // let token = store.getters["auth/getUser"]?.signInUserSession?.accessToken?.jwtToken;
  //
  // if (token) {
  //     config.headers["Authorization"] = `Bearer ${token}`;
  // }
  //

  return config;
}, error => {
  return Promise.reject(error);
});

export default {
  install() {
    Vue.prototype.$axios = devInstance;
  }
};
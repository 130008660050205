import Vue from "vue";
import store from "@/store";

let $vm = Vue.prototype;

export default {
  namespaced: true,
  state: () => ({
    _menu: []
  }),
  actions: {
    getMenu({ commit }) {
      return $vm.$axios.get(`/getMenu/1/${store.getters["lang/activeLang"]}`)
        .then(({ data }) => {
          commit("SET_MENU", data);
        });
    }
  },
  mutations: {
    SET_MENU(state, data) {
      state._menu = data.menu;
    }
  },
  getters: {
    menu(state) {
      return state._menu;
    },

  }
};

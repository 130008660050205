<template>
  <div class="layout page">
    <Header />
    <div data-scroll-container
         ref="scrollContainer"
         :class="{noScroll:!isNotHome}"
         class="main-view-container">
      <router-view />
      <Footer v-if="isNotHome" />
    </div>
    <home-footer v-if="!isNotHome" />
    <mouse v-if="!isMobile" />
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import { mapActions, mapGetters } from "vuex";
import HomeFooter from "@/components/HomeFooter";
import Mouse from "@/components/Mouse";

export default {
  data() {
    return {
      Loco: null,
      generalSeo: {
        image: require("../assets/images/Dodo-Designs.jpeg"),
        title: "Dodo Design - Creates set, interior, and product design",
        description: "We're the sisters behind the company, Dodo and Tamara Chkheidze," +
          "and are excited to tell you about what we do.",
        general: true
      }
    };
  },
  components: {
    Header,
    Footer,
    HomeFooter,
    Mouse
  },
  computed: {
    ...mapGetters({
      menu: "menu/menu"
    }),
    isMobile() {
      return window.innerWidth < 1024;
    },
    isNotHome() {
      return this.$route.params.lang ? this.$route.name !== "home" : false;
    },
    lang() {
      return this.$route.params.lang;
    },
    currentMenuSeo() {
      return this.menu.find(item => item.url === this.$route.name)?.seo || this.generalSeo;
    },
    seoTitle() {
      return this.currentMenuSeo?.general ? this.currentMenuSeo?.title
        : `${this.currentMenuSeo?.title} - Dodo Designs`;
    },
    keywords() {
      return this.currentMenuSeo?.keywords;
    },
    seoDescription() {
      return this.currentMenuSeo?.description;
    },
    ogImage() {
      return this.currentMenuSeo?.image;
    },
    ogImageWidth() {
      return this.currentMenuSeo?.["image-width"];
    },
    ogImageHeight() {
      return this.currentMenuSeo?.["image-height"];
    }
  },
  metaInfo() {
    return {
      title: this.seoTitle,
      meta: [
        {
          name: `description`,
          content: this.seoDescription
        },
        {
          property: `og:image`,
          name: `image`,
          content: this.ogImage
        },
        {
          property: `og:image:alt`,
          content: this.seoTitle
        },
        {
          property: `og:image:width`,
          content: this.ogImageWidth
        },
        {
          property: `og:image:height`,
          content: this.ogImageHeight
        },
        {
          name: "keyword",
          content: this.keywords
        }
      ]
    };
  },
  methods: {
    ...mapActions({
      setActiveLang: "lang/setActiveLang",
      setLoader: "loader/setLoader"
    })
  },
  created() {
    this.setActiveLang(this.lang || "en");
  }
};
</script>

<style lang="scss">
.main-view-container {
  width: 100vw;
  padding-top: 89px;

  &.noScroll {
    transform: unset !important;
  }
}

</style>

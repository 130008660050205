export default {
  namespaced: true,
  state: () => ({
    _scroll: null
  }),
  actions: {
    setScroll({ commit }, scroll) {
      commit("SET_SCROLL", scroll);
    }
  },
  mutations: {
    SET_SCROLL(state, data) {
      state._scroll = data;
    }
  },
  getters: {
    scroll(state) {
      return state._scroll;
    }

  }
};

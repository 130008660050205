import Vue from "vue";
import store from "@/store";

let $vm = Vue.prototype;

export default {
  namespaced: true, state: () => ({
    _products: [], _productsCategory: []
  }), actions: {
    getProducts({ commit }, props) {
      return $vm.$axios.get(`/products/getProducts/${store.getters["lang/activeLang"]}`, { params: props })
        .then(({ data }) => {
          commit("SET_PRODUCTS", data);
        });
    }, getProductsCategory({ commit }) {
      return $vm.$axios.get(`/products/getCategories/${store.getters["lang/activeLang"]}`)
        .then(({ data }) => {
          commit("SET_PRODUCTS_CATEGORY", data);
        });
    }
  }, mutations: {
    SET_PRODUCTS(state, data) {
      state._products = data.products;
    }, SET_PRODUCTS_CATEGORY(state, data) {
      state._productsCategory = data;
    }
  }, getters: {
    products(state) {
      return state._products;
    }, productsCategory(state) {
      return state._productsCategory;
    }

  }
};

import Vue from "vue";
let $vm = Vue.prototype;

export default {
   state: () => ({
      addresses: [],
   }),
   mutations: {
      setAddresses(state, countries) {
         state.countries = countries;
      }
   },
   actions: {
      addShippingAddress({dispatch}, address) {
         return new Promise((resolve, reject) => {
            $vm.$axios.post(`user/addShippingAddress`, address)
               .then((response) => {
                  dispatch('getUser')
                  resolve(response);
               })
               .catch((err) => {
                  reject(err);
               })
         })
      },
      deleteShippingAddress({dispatch}, id){
         return new Promise((resolve, reject) => {
            $vm.$axios.get(`/user/deleteShippingAddress/${id}`, { params: { id } })
               .then((response) => {
                  dispatch('getUser')
                  resolve(response)
               })
               .catch((err) => {
                  reject(err);
               });
         })
      },
      editShippingAddress({dispatch}, address) {
         return new Promise((resolve, reject) => {
            $vm.$axios.post(`user/editShippingAddress`, address)
               .then((response) => {
                  dispatch('getUser')
                  resolve(response);
               })
               .catch((err) => {
                  reject(err);
               })
         })
      },
   }
}
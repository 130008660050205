import Vue from "vue";
let $vm = Vue.prototype;

export default {
   state: () => ({
      translations: false
   }),
   actions: {
      addKey({ }, string) {
         $vm.$axios.post('/translations/addKey', { key: string })
            .then((response) => {
              //
            })
            .catch((err) => {
               // console.log(err)
            });
      },
      getTranslations({ state }) {
         return new Promise((resolve, reject) => {
            $vm.$axios.get('/translations')
               .then((response) => {
                  let translations = {};
                  if (response.data?.translations?.length) {
                     response.data.translations.forEach((item) => {
                        translations[item.key] = item.data;
                     })
                     state.translations = translations;
                  }
                  resolve(translations);
               })
               .catch((err) => {
                  console.log(err)
                  reject();
               });
         })
      }
   }
}
<template>
  <transition name="popup">
    <div v-if="is_open" class="popup">
      <div class="popup_bg is-dark"></div>
      <transition name="popup-slide" mode="out-in">
        <signin v-if="active === 'signin'" @onChnageTab="(value) => active = value" key="signin"/>
        <forgotpassword v-else-if="active === 'forgotpassword'" @onChnageTab="(value) => active = value" key="forgot"/>
      </transition>
    </div>
  </transition>
</template>

<script>
import ClickOutside from 'vue-click-outside'
import signin from './SignIn.vue';
import forgotpassword from './ForgotPassword.vue';

export default {
  components: {
    signin,
    forgotpassword
  },
  data() {
    return {
      active: 'signin'
    }
  },
  computed: {
    is_open() {
      return this.$store.state.auth.is_open;
    }
  },
  watch: {
    is_open: {
      handler(next) {
        !next ? this.active = 'signin' : '';
      }
    }
  }
};
</script>

<style  scoped>
.w-form-label {
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}
</style>
<template>
   <div class="popup_window">
      <div class="popup_inner">
         <div class="popup_close-btn is-absolute" @click="onClose"></div>
         <LoginForm @onChnageTab="$emit('onChnageTab', 'forgotpassword')"/>
         <span class="black-link pointer">{{ $tr('Have not an account yet?') }}</span>
         <SignupForm/>
      </div>
   </div>
</template>

<script>
import LoginForm from './LoginForm.vue';
import SignupForm from './SignupForm.vue';
export default {
   name: 'signIn',
   components:{
      LoginForm,
      SignupForm
   },
   methods: {
      onClose() {
         this.$store.commit('toggleAuthModal');
      }
   }
}
</script>

<style lang="scss" scoped>
.pointer {
   cursor: pointer;
}
</style>
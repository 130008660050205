<template>
   <div class="w-form" :class="checkout ? 'checkout_form-wrapper' : 'form_block'">
      <form id="wf-form-Login-form" name="wf-form-Login-form" data-name="Login form" method="get" class="form"
         @submit.prevent>
         <h2 class="form_heading">{{ $tr('Log in') }}</h2>
         <p>{{ $tr('Good to see you again!') }} </p>
         <div class="form-fields">
            <input type="email" class="text-field is-name w-input" maxlength="256" name="name" data-name="Name"
               :placeholder="$tr('Email')" id="name" v-model="email">
            <input type="password" class="text-field is-password w-input" maxlength="256" name="Password"
               data-name="Password" :placeholder="$tr('Password')" id="Password" v-model="password">
         </div>
         <div class="form_bottom-line">
            <span class="form_forgot-password pointer" @click="onForgotPassword">
               {{ $tr('Forgot your password?') }}
            </span>
         </div>
         <input  type="submit" :value="$tr('Log in')" :data-wait="$tr('Please wait...')" class="btn w-button" @click.prevent="onLogin">
      </form>
      <div class="contact_form-success w-form-done"></div>
      <transition name="fade-up">
         <div v-if="error" class="w-form-fail block">
            <div v-for="err in error_messages" :key="err">{{ $tr(err) }}</div>
         </div>
      </transition>
   </div> 
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { required, email, minLength, helpers } from '@vuelidate/validators'
export default {
   name: 'LoginForm',
   props: {
      checkout: {
         type: Boolean,
         default: false
      }
   },
   setup() {
      return { v$: useVuelidate() }
   },
   data() {
      return {
         email: '',
         password: '',
         error: false,
         error_messages: []
      }
   },
   methods: {
      check() {
         return new Promise((resolve, reject) => {
            if (!this.v$.$silentErrors.length) resolve()
            else {
               this.v$.$silentErrors.forEach((err) => this.error_messages.push(err.$message));
               this.error = true;
            }
         })
      },
      async onLogin() {
         this.error = false;
         this.error_messages = [];
         await this.check();
         this.$store.dispatch('login', { email: this.email, password: this.password })
            .then(() => {
               this.checkout ?
                  this.$router.push({ path: this.$route.path, query: { step: 'delivery' } })
                  : this.$store.commit('toggleAuthModal'); 
            })
            .catch((err) => {
               this.error_messages.push(err.data.message)
               this.error = true;
            })
      },
      onForgotPassword() {
         this.$emit('onChnageTab', 'forgotpassword');
      }
   },
   validations() {
      return {
         email: { required: helpers.withMessage('email is requred', required), email: helpers.withMessage('please enter valid email', email) },
         password: {
            required: helpers.withMessage('password is requred', required),
            minLength: helpers.withMessage('password min length is 8', minLength(8))
         }
      }
   }
}
</script>

<style lang="scss" scoped>
.pointer {
   cursor: pointer;
}
</style>
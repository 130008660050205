export default {
  namespaced: true,
  state: () => ({
    _loader: false
  }),
  actions: {
    setLoader({ commit }, data) {
      commit("SET_LOADER", data);
    }
  },
  mutations: {
    SET_LOADER(state, data) {
      state._loader = data;
    }
  },
  getters: {
    loader(state) {
      return state._loader;
    }

  }
};

import Vue from "vue";
import store from "@/store";

let $vm = Vue.prototype;

export default {
  namespaced: true,
  state: () => ({
    _about: []
  }),
  actions: {
    getAbout({ commit }) {
      return $vm.$axios.get(`/getAbout/${store.getters["lang/activeLang"]}`)
        .then(({ data }) => {
          console.log(data)
          commit("SET_ABOUT", data.about);
        });
    }
  },
  mutations: {
    SET_ABOUT(state, data) {
      state._about = data;
    }
  },
  getters: {
    about(state) {
      return state._about;
    }

  }
};

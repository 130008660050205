<template>
  <div
    :class="{
    'load':load}"
    class="grid-wrapper">
    <mouse-changer type="Landing">
      <div :class="{
     'from-left':fromLeft,
    'from-right':fromRight,
    'load':load}"
           class="grid">
        <div id="vertical" class="vertical gap">
          <div class="vertical-grid-hidden-container right  gap">
            <div v-for="index in 3" :key="index"
                 class="grid-vertical-item"></div>
          </div>
          <div id="mainGrid" class="vertical-grid-container gap">
            <div v-for="index in 7" :key="index"
                 class="grid-vertical-item"></div>
          </div>
          <div class="vertical-grid-hidden-container left gap">
            <div v-for="index in 3" :key="index"
                 class="grid-vertical-item"></div>
          </div>
        </div>
        <div class="horizontal gap">
          <div v-for="index in 3" :key="index"
               class="grid-horizontal-item
           grid-horizontal-item-id"></div>
          <div class="grid-horizontal-item
       grid-horizontal-item-id
       mobile-horizontal-item"></div>
        </div>
        <div :class="{active:fromLeft}"
             class="project-images hide-images">
          <div class="image-container">
            <video class="about_banner_img"
                   autoplay
                   loop
                   muted
                   v-if="projectVideo"
            >
              <source :src="projectVideo" type="video/mp4">
              Your browser does not support the video tag.
            </video>
          </div>
          <div v-for="item in projectImages" class="image-container">
            <img :src="item.file" alt="">
          </div>
        </div>
        <div :class="{active:fromRight}"
             v-if="shopImages.length"
             class="shop-images hide-images">
          <div class="image-container">
            <img :src="shopImages[0].file" alt="">
          </div>
          <div class="image-container">
            <video class="about_banner_img"
                   autoplay
                   loop
                   muted
                   v-if="shopVideo"
            >
              <source :src="shopVideo" type="video/mp4">
              Your browser does not support the video tag.
            </video>
          </div>
          <div class="image-container">
            <img :src="shopImages[1].file" alt="">
          </div>
          <div class="image-container">
            <img :src="shopImages[2].file" alt="">
          </div>
        </div>
      </div>
    </mouse-changer>
    <mouse-changer type="BlendMode">
      <div :class="{active:!showHeader}" class="menu-items">
        <h1 @mouseover="mouseOver(index===0)"
            @mouseout="mouseOut(index===0)"
            v-for="(item,index) in menuData"
            @click="changeRoute(index)"
            class="title">{{ item.title }}</h1>
      </div>
    </mouse-changer>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import MouseChanger from "@/components/MouseChanger";

export default {
  components: { MouseChanger },
  props: {
    data: {
      type: Array
    }
  },
  data() {
    return {
      fromLeft: false,
      fromRight: false,
      load: false,
      titleTimeout: null,
      resizeTimeOut: null,
      transformValueLeft: 0,
      transformValueRight: 0,
      animationTimeoutOver: null,
      animationTimeoutOut: null,
      closing: false
    };
  },
  computed: {
    ...mapGetters({
      showHeader: "header/getShowHeader",
      lang: "lang/activeLang"
    }),
    projectImages() {
      return this.data?.[0]?.images;
    },
    shopImages() {
      return this.data?.[1]?.images || [];
    },
    shopVideo() {
      return this.data?.[1]?.videos?.[0]?.file;
    },
    projectVideo() {
      return this.data?.[0]?.videos?.[0]?.file;
    },
    menuData() {
      return this.data?.map(item => item.menu);
    }
  },
  methods: {
    ...mapActions({
      setHeaderBg: "header/setHeaderBg"
    }),
    mouseOver(value) {
      if (window.innerWidth < 1024) return;
      let grid = document.querySelector("#vertical");
      grid.style.transitionDuration = `1s`;
      if (this.animationTimeoutOver) clearTimeout(this.animationTimeoutOver);
      if (this.closing) {
        this.animationTimeoutOver = setTimeout(() => {
          if (value) {
            this.fromLeft = true;
            this.setHeaderBg("#F4D4DF");
          } else {
            this.fromRight = true;
            this.setHeaderBg("#0F0F0F");
          }
        }, 500);
        this.gridAnimate(value);

        grid.style.transitionDuration = `2s`;
        return;
      }
      if (value) {
        this.fromLeft = true;
        this.setHeaderBg("#F4D4DF");
      } else {
        this.fromRight = true;
        this.setHeaderBg("#0F0F0F");
      }

      this.gridAnimate(value);
    },
    mouseOut(value) {
      let grid = document.querySelector("#vertical");
      grid.style.transitionDuration = `1s`;
      if (this.animationTimeoutOut) clearTimeout(this.animationTimeoutOut);
      if (this.animationTimeoutOver) clearTimeout(this.animationTimeoutOver);

      this.closing = true;
      if (value) {
        this.fromLeft = false;
      } else {
        this.fromRight = false;
      }
      this.animationTimeoutOut = setTimeout(() => {
        this.closing = false;
      }, 500);

      this.setHeaderBg("");
      this.setGridTransform(0);
    },
    setImages() {
      let horizontalItems = document.querySelectorAll(".grid-horizontal-item-id");
      let projectImages = document.querySelectorAll(".project-images .image-container");
      let shopImages = document.querySelectorAll(".shop-images .image-container");
      let secondHorizontalItem = horizontalItems[1]?.getBoundingClientRect();
      let thirdHorizontalItem = horizontalItems[2]?.getBoundingClientRect();
      let gap = thirdHorizontalItem.top - secondHorizontalItem.top;

      if (window.innerWidth < 1023) {
        let fourthHorizontalItem = horizontalItems[3]?.getBoundingClientRect();

        projectImages[0].style.top = secondHorizontalItem.top + "px";
        projectImages[1].style.top = fourthHorizontalItem.top + "px";
        shopImages[0].style.top = fourthHorizontalItem.top + "px";
        return;
      }

      //pr-images-set
      projectImages[0].style.top = secondHorizontalItem.top + "px";
      projectImages[1].style.top = thirdHorizontalItem.top + "px";
      projectImages[2].style.top = thirdHorizontalItem.top + gap + "px";
      if (projectImages[3]) projectImages[3].style.top = thirdHorizontalItem.top + gap + "px";

      //sh-images-set
      shopImages[0].style.top = thirdHorizontalItem.top + "px";
      shopImages[1].style.top = secondHorizontalItem.top + "px";
      shopImages[2].style.top = thirdHorizontalItem.top + gap + "px";
      if (projectImages[3]) shopImages[3].style.top = thirdHorizontalItem.top + gap + "px";
    },
    setGridTransform(transformValue) {
      let grid = document.querySelector("#vertical");

      grid.style.transform = `translateX(${transformValue}px)`;
    },
    gridAnimate(fromLeft = true) {
      let transformValue = fromLeft ? this.transformValueLeft : this.transformValueRight;
      this.setGridTransform(transformValue);
    },
    setInitTransformsValues() {
      let mainCont = document.querySelector("#mainGrid")?.getBoundingClientRect();
      this.transformValueLeft = -mainCont.left;
      this.transformValueRight = mainCont.right - mainCont.width;
    },
    resizeHandler() {
      if (this.resizeTimeOut) clearTimeout(this.resizeTimeOut);
      this.resizeTimeOut = setTimeout(() => {
        this.setInitTransformsValues();
        this.setImages();
      }, 800);
    },
    changeRoute(indx) {
      this.$router.push({ path: `/${this.lang}/${this.data?.[indx]?.title}` });
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.titleTimeout = setTimeout(() => {
        document.querySelectorAll(".menu-items .title")?.forEach(item => {
          item.style.transitionDelay = "0s";
        });
        this.setInitTransformsValues();
      }, 1200);
      window.addEventListener("resize", this.resizeHandler);
    });
  },
  watch: {
    data() {
      setTimeout(() => {
        this.setImages();
        this.load = true;
      }, 1);
    }
  },
  beforeDestroy() {
    if (this.resizeTimeOut) clearTimeout(this.resizeTimeOut);
    if (this.titleTimeout) clearTimeout(this.titleTimeout);
    window.removeEventListener("resize", this.resizeHandler);
  }
};
</script>

<style scoped lang="scss">
$ease: cubic-bezier(.71, 0, .31, 1);
$time: 1s;
$gap: 161px;
.grid-wrapper {
  &.load {
    .grid-horizontal-item {
      transform: translateX(0) !important;
    }

    .grid-vertical-item {
      &:first-child {
        transform: translateY(0) !important;
      }

      &:nth-child(2), &:nth-child(6) {
        transform: translateY(0) !important;
      }

      &:nth-child(3) {
        transform: translateY(-5%) !important;
      }

      &:nth-child(4) {
        transform: translateY(12.5%) !important;
      }

      &:nth-child(5) {
        transform: translateY(8%) !important;
      }

      &:nth-child(7) {
        transform: translateY(-2.5%) !important;
      }
    }

    .menu-items {
      .title {
        letter-spacing: 0.25em;
        opacity: 1;

        &:hover {
          letter-spacing: 0.3em !important;
          @media only screen and (max-width: 1023px) {
            letter-spacing: 0.25em !important;
          }

          &:after {
            transform: translateX(-50%) scale(1);
          }
        }
      }
    }
  }

  .menu-items {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    opacity: 0;
    transition: all .1s ease;

    &.active {
      opacity: 1;
      transform: translate(-50%, -50%);
    }

    .title {
      width: fit-content;
      font-style: normal;
      font-weight: 300;
      font-size: 89px;
      line-height: 114px;
      letter-spacing: 0.1em;
      opacity: 0;
      text-transform: uppercase;
      cursor: pointer;
      position: relative;
      transition: all .7s .9s cubic-bezier(0, .12, .27, 1.01);
      @media only screen and (max-width: 1023px) {
        color: #000000;
        font-weight: bold;
      }

      &:after {
        content: '';
        position: absolute;
        width: 88px;
        height: 4px;
        background: #F4D4DF;
        bottom: -24px;
        left: 50%;
        transition: all .45s $ease;
        transform: translateX(-50%) scale(0);
        @media only screen and (max-width: 1650px) and (min-width: 1440px) {
          bottom: -3px;
        }
        @media only screen and (max-width: 1439px) and (min-width: 1280px) {
          bottom: -19px;
        }
        @media only screen and (max-width: 1279px) and (min-width: 1024px) {
          bottom: -10px;
        }
        @media only screen and (max-width: 1023px) {
          display: none;
        }
      }

      @media only screen and (max-width: 1439px) and (min-width: 1024px) {
        font-size: 55px;
        line-height: 71px;
      }
      @media only screen and (max-width: 1023px) {
        font-size: 34px;
        line-height: 44px;
      }

      &:nth-child(2) {
        margin-top: 50px;
        animation-delay: 1s;

        &:after {
          background: black;
        }

        @media only screen and (max-width: 1650px) and (min-width: 1440px) {
          margin-top: 8px;
        }
        @media only screen and (max-width: 1439px) and (min-width: 1280px) {
          margin-top: 38px;
        }
        @media only screen and (max-width: 1279px) and (min-width: 1024px) {
          margin-top: 14px;
        }
      }
    }
  }
}

.grid {
  height: 100vh;
  overflow-x: hidden;
  top: 0;
  z-index: 2;
  width: 100%;

  &.from-left {
    .vertical-grid-hidden-container.left {
      .grid-vertical-item {
        opacity: 1 !important;
        transform: translateY(0) scaleY(1) !important;

        &:nth-child(2) {
          transform: translateY(-5%) scaleY(1) !important;
        }

        &:nth-child(3) {
          transform: translateY(-4%) scaleY(1) !important;
        }
      }
    }
  }

  &.from-right {
    .vertical-grid-hidden-container.right {
      .grid-vertical-item {
        opacity: 1 !important;
        transform: translateY(0) scaleY(1) !important;

        &:nth-child(2) {
          transform: translateY(-5%) scaleY(1) !important;
        }

        &:nth-child(3) {
          transform: translateY(-3%) scaleY(1) !important;
        }
      }
    }
  }


  .gap {
    grid-gap: $gap;
  }

  .vertical {
    display: flex;
    justify-content: center;
    position: absolute;
    left: 0;
    height: 100%;
    width: 100%;
    transition: all $time $ease;
  }

  .vertical-grid-container {
    position: relative;
    width: fit-content;
    height: 100vh;
    display: flex;
    align-items: center;
    transition: all $time $ease;

    .grid-vertical-item {
      transition: all $time $ease;

      &:first-child {
        transform: translateY(-120%);
        transition-delay: .4s;
      }

      &:nth-child(2) {
        transform: translateY(120%);
        transition-delay: .1s;
      }

      &:nth-child(3) {
        transform: translateY(-120%);
        transition-delay: .3s;
      }

      &:nth-child(4) {
        transform: translateY(120%);
        transition-delay: .25s;
      }

      &:nth-child(5) {
        transform: translateY(-120%);
        transition-delay: .2s;
      }

      &:nth-child(6) {
        transform: translateY(-120%);
        transition-delay: .1s;
      }

      &:nth-child(7) {
        transform: translateY(120%);
      }
    }
  }

  .grid-vertical-item {
    width: 2px;
    height: 100%;
    background: rgba(149, 107, 84, 0.2);

    &:first-child {
      height: 70%;
    }

    &:nth-child(2), &:nth-child(6) {
      height: 55%;
    }

    &:nth-child(3) {
      height: 60%;
      transform: translateY(-7%);
    }

    &:nth-child(4) {
      height: 80%;
      transform: translateY(12.5%);
    }

    &:nth-child(5) {
      height: 72%;
      transform: translateY(8%);
    }

    &:nth-child(7) {
      height: 65%;
      transform: translateY(-10%);
    }
  }

  .vertical-grid-hidden-container {
    position: relative;
    height: 100vh;
    top: 0;
    display: flex;
    align-items: center;
    width: fit-content;
    right: 0;
    //transform: translateX(100%);
    transition: all $time $ease;

    &.right {
      //transform: translateX(-100%);
      //left: 0;
      //right: unset;
    }

    .grid-vertical-item {
      transition: all $time .2s $ease;
      transform-origin: top;
      opacity: 0;

      &:nth-child(1) {
        height: 55%;
        transform: translateY(-60%) scaleY(0);
        transition: all $time .4s $ease;
      }

      &:nth-child(2) {
        height: 60%;
        transform: translateY(60%) scaleY(0);
        transform-origin: bottom;
      }

      &:nth-child(3) {
        height: 65%;
        transform: translateY(-65%) scaleY(0);
      }
    }
  }

  .horizontal {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media only screen and (max-width: 1023px) {
      transform: translateY(-65%);
    }

    .grid-horizontal-item {
      height: 2px;
      width: 100%;
      background: rgba(149, 107, 84, 0.2);
      transform: translateX(120%);
      transition: all 1.2s $ease;

      &:nth-child(1) {
        width: 70%;
        transition-delay: .2s;
      }

      &:nth-child(2) {
        width: 83%;
        align-self: flex-start;
        transition-delay: .3s;
      }

      &:nth-child(3) {
        width: 85%;
        align-self: flex-end;
        transform: translateX(-120%);
      }

      @media only screen and (max-width: 1023px) {
        width: 100% !important;
      }

      &.mobile-horizontal-item {
        display: none;
        @media only screen and (max-width: 1023px) {
          display: block;
        }
      }
    }
  }


  .hide-images {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    top: 0;
    pointer-events: none;

    &.active {
      img {
        transition: all $time $ease;
        transform: translateX(0) translateZ(0) !important;
      }

      .image-container {
        transition: all $time $ease;
        clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
      }

      &.shop-images {
        .image-container {
          clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%) !important;
        }

      }
    }

    .image-container {
      position: absolute;
      overflow: hidden;
      clip-path: polygon(100% 0%, 100% 0%, 100% 100%, 100% 100%);
      transition: all 1s $ease;
      @media only screen and (max-width: 1023px) {
        clip-path: unset;
      }
    }

    &.project-images {
      img {
        transform-origin: right;
      }

      .image-container {

        &:first-child {
          left: 165px;
          width: 486px;
          height: 320px;
          transform: translateY(-100%);
          @media only screen and (max-width: 1023px) {
            left: unset;
            right: 0;
            width: 186px;
            height: 94px;
          }
        }

        &:nth-child(2) {
          left: calc(9 * 161px + 19px);
          width: 319.67px;
          height: 480px;
          transform: translateY(-100%);
          @media only screen and (max-width: 1023px) {
            left: unset;
            right: 0;
            width: 93px;
            height: 92px;
            transform: unset;
          }
        }

        &:nth-child(3) {
          left: calc(2 * 164px);
          width: 486px;
          height: 160px;
          @media only screen and (max-width: 1023px) {
            display: none;
          }
        }

        &:nth-child(4) {
          left: calc(8 * 161px + 16px);
          width: 320px;
          height: 240px;
          @media only screen and (max-width: 1023px) {
            display: none;
          }
        }
      }
    }

    &.shop-images {
      img {
        transform-origin: left;
        transform: translateX(-15px) translateZ(0);
        @media only screen and (max-width: 1023px) {
          transform: translateX(0) translateZ(0);
        }
      }

      .image-container {
        display: flex;
        align-items: flex-end;
        clip-path: polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%) !important;
        @media only screen and (max-width: 1023px) {
          clip-path: unset !important;
          transition: unset;
        }

        &:first-child {
          right: calc((9 * 161px + 18px));
          top: 12vh;
          width: 320.67px;
          height: 480px;
          transform: translateY(-100%);
          @media only screen and (max-width: 1023px) {
            left: 0;
            right: unset;
            width: 92px;
            height: 186px;
            transform: unset;
          }
        }

        &:nth-child(2) {
          right: calc(1 * 166px);
          top: 12vh;
          width: 320px;
          height: 320px;
          transform: translateY(-100%);
          @media only screen and (max-width: 1023px) {
            display: none;
          }
        }

        &:nth-child(3) {
          right: calc(8 * 161px + 18px);
          width: 160px;
          height: 240px;
          @media only screen and (max-width: 1023px) {
            display: none;
          }
        }

        &:nth-child(4) {
          right: calc(3 * 165px);
          width: 480px;
          height: 160.33px;
          @media only screen and (max-width: 1023px) {
            display: none;
          }
        }
      }
    }

    img {
      min-width: calc(100% + 20px);
      left: 0;
      position: relative;
      height: 100%;
      object-fit: cover;
      transition: all $time $ease;
      -webkit-backface-visibility: hidden;
      image-rendering: crisp-edges;
      transform: translateX(15px) translateZ(0);
      @media only screen and (max-width: 1023px) {
        transform: translateX(0) translateZ(0);
      }
    }
  }

  @media only screen and (max-width: 1650px) and (min-width: 1440px) {
    $gap: 120px;
    .gap {
      grid-gap: $gap;
    }
    .hide-images {
      &.project-images {
        .image-container {
          &:first-child {
            left: 122px;
            width: 366px;
            height: 200px;
          }

          &:nth-child(2) {
            left: calc(9 * 120px + 19px);
            width: 194px;
            height: 291px;
          }

          &:nth-child(3) {
            left: calc(2 * 122px);
            width: 364px;
            height: 102px;
          }

          &:nth-child(4) {
            left: calc(8 * 120px + 16px);
            width: 238px;
            height: 179px;
          }
        }
      }

      &.shop-images {
        .image-container {
          &:first-child {
            right: calc((9 * 120px + 18px));
            width: 214px;
            height: 319px;
          }

          &:nth-child(2) {
            right: calc(1 * 125px);
            width: 240px;
            height: 233px;
          }

          &:nth-child(3) {
            right: calc(8 * 120px + 18px);
            width: 119px;
            height: 179px;
          }

          &:nth-child(4) {
            right: calc(3 * 122px);
            width: 364px;
            height: 102px;
          }
        }
      }
    }

  }
  @media only screen and (max-width: 1439px) and (min-width: 1280px) {
    $gap: 106px;
    .gap {
      grid-gap: $gap;
    }

    .hide-images {
      &.project-images {
        .image-container {
          &:first-child {
            left: 110px;
            width: 321px;
            height: 212px;
          }

          &:nth-child(2) {
            left: calc(9 * 106px + 18px);
            width: 161px;
            height: 242px;
          }

          &:nth-child(3) {
            left: calc(2 * 106px + 4px);
            width: 324px;
            height: 106px;
          }

          &:nth-child(4) {
            left: calc(8 * 106px + 18px);
            width: 214px;
            height: 161px;
          }
        }
      }

      &.shop-images {
        .image-container {
          &:first-child {
            right: calc((9 * 108px));
            width: 178px;
            height: 266px;
          }

          &:nth-child(2) {
            right: calc(1 * 110px);
            width: 213px;
            height: 213px;
          }

          &:nth-child(3) {
            right: calc(8 * 106px + 16px);
            width: 106px;
            height: 157px;
          }

          &:nth-child(4) {
            right: calc(3 * 108px);
            width: 324px;
            height: 110px;
          }
        }
      }
    }

  }
  @media only screen and (max-width: 1279px) and (min-width: 768px) {
    $gap: 84px;
    .gap {
      grid-gap: $gap;
    }

    .hide-images {
      &.project-images {
        .image-container {

          &:first-child {
            left: 88px;
            width: 256px;
            height: 167px;
          }

          &:nth-child(2) {
            left: calc(9 * 84px + 18px);
            width: 137px;
            height: 205px;
          }

          &:nth-child(3) {
            left: calc(2 * 87px);
            width: 256px;
            height: 85px;
          }

          &:nth-child(4) {
            left: calc(8 * 84px + 18px);
            width: 210px;
            height: 158px;
          }
        }
      }

      &.shop-images {
        .image-container {

          &:first-child {
            right: calc((9 * 84px + 18px));
            width: 178px;
            height: 266px;
          }

          &:nth-child(2) {
            right: calc(1 * 86px);
            width: 172px;
            height: 176px;
          }

          &:nth-child(3) {
            right: calc((8 * 84px + 18px));
            width: 84px;
            height: 129px;
          }

          &:nth-child(4) {
            right: calc(3 * 86px);
            width: 258px;
            height: 88px;
          }
        }
      }
    }


  }
  @media only screen and (max-width: 767px) {
    $gap: 94px;

    .gap {
      grid-gap: $gap;
    }
  }

  @keyframes text_anim {
    0% {
      letter-spacing: 0;
      opacity: 0;
    }
    100% {
      letter-spacing: .25em;
      opacity: 1;
    }
  }
}
</style>
